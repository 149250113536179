/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, ContactForm } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="css-e4mn1b --full pb--30 pt--50" name={"uvod"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31248/bd29a5af094e486a8b08b8af39a65e8b_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31248/bd29a5af094e486a8b08b8af39a65e8b_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31248/bd29a5af094e486a8b08b8af39a65e8b_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31248/bd29a5af094e486a8b08b8af39a65e8b_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31248/bd29a5af094e486a8b08b8af39a65e8b_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31248/bd29a5af094e486a8b08b8af39a65e8b_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31248/bd29a5af094e486a8b08b8af39a65e8b_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31248/bd29a5af094e486a8b08b8af39a65e8b_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box fs--72" content={"<span style=\"color: white\">Alina &amp; Denis<br>Wedding day<br></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--80" name={"informace"} style={{"backgroundColor":"rgba(255,255,255,1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--30 w--300 ls--0" style={{"maxWidth":880}} content={"Мы не представляем этот праздник без Вас - близких и дорогих нам людей. <br>И будем счастливы разделить с вами радость свадебного торжества!<br><br><br>| 19.06.2023 |&nbsp;14:30 |&nbsp;Mlýn Na Dobré Vodě |&nbsp;<br><br>| Na dobré vodě 104 00 |<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1fld2j2 css-1bfehxx pb--60 pt--60" name={"0hrlu5lkviqd"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/31248/2580cdf00e16496fb69dd308cd3a5fcc_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/31248/2580cdf00e16496fb69dd308cd3a5fcc_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/31248/2580cdf00e16496fb69dd308cd3a5fcc_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/31248/2580cdf00e16496fb69dd308cd3a5fcc_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/31248/2580cdf00e16496fb69dd308cd3a5fcc_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/31248/2580cdf00e16496fb69dd308cd3a5fcc_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/31248/2580cdf00e16496fb69dd308cd3a5fcc_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/31248/2580cdf00e16496fb69dd308cd3a5fcc_s=3000x_.jpeg);
    }
  
background-position: 51% 19%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--43" content={"<span style=\"color: var(--color-blend--95);\"><br><br><br><br></span><br><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left" name={"program"} style={{"paddingTop":67,"marginBottom":14,"paddingBottom":1}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--62" style={{"maxWidth":880}} content={"Plan of the day<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--80" name={"program-3"} style={{"paddingTop":0}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1300,"paddingBottom":0}} columns={"1"}>
            
            <ColumnWrapper className="pb--20 pl--20 pr--20">
              
              <Title className="title-box fs--62" content={"14:30"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"Welcome Prosecco&nbsp;&amp; Canapes<br>"}>
              </Text>

              <Image alt={""} src={"https://cdn.swbpg.com/o/g/Ikonky/svatebni/17edc39d593b4f768700b843c341b22d.svg"} sizes={"100vw"} style={{"maxWidth":132,"marginTop":0}} srcSet={""} svgColor={"rgba(0,0,0,1)"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--20 pl--20 pr--20">
              
              <Title className="title-box fs--62" content={"15:00"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"Церемония<br>"}>
              </Text>

              <Image alt={""} src={"https://cdn.swbpg.com/o/g/Ikonky/svatebni/3cc5b5a96ef04323b543f2b8b4a55fe3.svg"} sizes={"100vw"} style={{"maxWidth":131,"marginTop":0}} srcSet={""} svgColor={"rgba(0,0,0,1)"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--20 pl--20 pr--20">
              
              <Title className="title-box fs--62" content={"16:00"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"Cocktail hour&nbsp;&amp; Photos<br>"}>
              </Text>

              <Image alt={""} src={"https://cdn.swbpg.com/o/g/Ikonky/svatebni/51712b7212824833b658ef7cad5f50b9.svg"} sizes={"100vw"} style={{"maxWidth":132,"marginTop":0}} srcSet={""} svgColor={"rgba(0,0,0,1)"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--20 pl--20 pr--20">
              
              <Title className="title-box fs--62" content={"17:00"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"Свадебный ужин<br>"}>
              </Text>

              <Image alt={""} src={"https://cdn.swbpg.com/o/g/Ikonky/svatebni/29288f33de65415482aafafd30dca7c3.svg"} sizes={"100vw"} style={{"maxWidth":132,"marginTop":0}} srcSet={""} svgColor={"rgba(0,0,0,1)"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--20 pl--20 pr--20">
              
              <Title className="title-box fs--62" content={"22:00"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"Разрезание торта<br>"}>
              </Text>

              <Image alt={""} src={"https://cdn.swbpg.com/o/g/Ikonky/svatebni/cea5a113adad4656959b69ee3d5fbc2d.svg"} sizes={"100vw"} style={{"maxWidth":132,"marginTop":0}} srcSet={""} svgColor={"rgba(0,0,0,1)"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pl--20 pr--20" style={{"paddingBottom":0}}>
              
              <Title className="title-box fs--62" content={"23:00"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"Party<br>"}>
              </Text>

              <Image alt={""} src={"https://cdn.swbpg.com/o/g/Ikonky/svatebni/4245602635b04eb1af32c41fbe7bb1e8.svg"} sizes={"100vw"} style={{"maxWidth":132,"marginTop":0}} srcSet={""} svgColor={"rgba(0,0,0,1)"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-yc5d67 css-6vsx62 pt--80" name={"obrazek"} style={{"marginBottom":0,"paddingBottom":169}} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/31248/7b3d6ca3e9994ea387202fcafee4d405_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/31248/7b3d6ca3e9994ea387202fcafee4d405_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/31248/7b3d6ca3e9994ea387202fcafee4d405_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/31248/7b3d6ca3e9994ea387202fcafee4d405_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/31248/7b3d6ca3e9994ea387202fcafee4d405_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/31248/7b3d6ca3e9994ea387202fcafee4d405_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/31248/7b3d6ca3e9994ea387202fcafee4d405_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/31248/7b3d6ca3e9994ea387202fcafee4d405_s=3000x_.jpeg);
    }
  
background-position: 48% 21%;
    `}>
          
          <ColumnWrap className="column__flex el--1 pb--80 pt--100 flex--center" style={{"marginBottom":133}} columns={"1"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--80" name={"polozky"} style={{"backgroundColor":"rgba(255,255,255,1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"paddingBottom":0}}>
              
              <Title className="title-box fs--62" style={{"maxWidth":880}} content={"Dress-code<br>"}>
              </Title>

              <Image alt={""} src={"https://cdn.swbpg.com/t/31248/d32a00c1847a48f08f1a9d306e688825_e=83x60x891x445_s=660x_.jpeg"} sizes={"100vw"} style={{"maxWidth":437,"marginTop":0}} srcSet={"https://cdn.swbpg.com/t/31248/d32a00c1847a48f08f1a9d306e688825_e=83x60x891x445_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/31248/d32a00c1847a48f08f1a9d306e688825_e=83x60x891x445_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/31248/d32a00c1847a48f08f1a9d306e688825_e=83x60x891x445_s=860x_.jpeg 860w"}>
              </Image>

              <Title className="title-box fs--26" content={"Мы старались сделать наше торжество красивым, поэтому будем очень признательны, если в день свадьбы у вас получиться придерживаться цветовой палитры.<br><br>"}>
              </Title>

              <Title className="title-box fs--48" content={"Свадебный подарок<br>"}>
              </Title>

              <Title className="title-box fs--26" content={"Мы не хотим утруждать вас выбором подарка, <br>поэтому будем рады вашему вкладу в бюджет нашей молодой семьи.<br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80" name={"program"} style={{"paddingTop":0}}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"paddingTop":0}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--48" style={{"maxWidth":1080,"paddingTop":0}} content={"&nbsp;Подтвердите пожалуйста ваше участие!"}>
              </Title>

              <Text className="text-box lh--2 mt--25" style={{"maxWidth":880}} content={"Убедительная просьба для каждого гостя, указать выбор блюда в указанной форме. <br>Так персонал сможет позаботиться о вас наилучшим образом!<br>"}>
              </Text>

              <Text className="text-box" content={"Starter<br>1. Ростбиф / ремулад из каперсов / горчица / маринованный лук / свежая зелень <br>2. Паштет из кролика / абрикос / редис / молодой шпинат <br><br>Main course<br>1. Лососевая форель / петрушка / брюссельская капуста / картофельное конфи / соус\nБерр Блан <br>2. Куриная грудка / ризотто с крупой / кукуруза / лук-порей&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80" name={"program-2"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="pb--20">
              
              <ContactForm className="--shape2 fs--22" style={{"maxWidth":1050}} action={"/contact"} fields={[{"name":"Имя и Фамилия","type":"text","required":true,"placeholder":"Напишите ваше полное имя"},{"name":"Номер телефона","type":"text","placeholder":""},{"name":"Блюдо на выбор","type":"text","placeholder":"Напишите пожалуйста номер двух блюд"},{"name":"Отправить форму","type":"submit"}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"fotogalerie"} layout={"l12"}>
          
          <ColumnWrap className="column__flex --center el--3 --full" columns={"3"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/31248/2fbe070808ba40e9bb73ff29b74692f2_e=99x83x681x682_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/31248/2fbe070808ba40e9bb73ff29b74692f2_e=99x83x681x682_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/31248/2fbe070808ba40e9bb73ff29b74692f2_e=99x83x681x682_s=660x_.jpeg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/31248/4cec275bdb9342f989d15ba23cd43a03_e=43x58x681x682_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/31248/4cec275bdb9342f989d15ba23cd43a03_e=43x58x681x682_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/31248/4cec275bdb9342f989d15ba23cd43a03_e=43x58x681x682_s=660x_.jpeg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/31248/2f3b20d0a11141d59db3807caae1df4b_e=35x381x819x819_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/31248/2f3b20d0a11141d59db3807caae1df4b_e=35x381x819x819_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/31248/2f3b20d0a11141d59db3807caae1df4b_e=35x381x819x819_s=660x_.jpeg 660w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--80 pt--80" name={"paticka"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--43" content={"По всем вопросам вы можете обратиться к нашему организатору:<br>"}>
              </Title>

              <Title className="title-box fs--26" content={"Elizaveta Volkhina<br>+ 420 775 314 669<br>info@brilliant-wedding.cz<br>"}>
              </Title>

              <Title className="title-box fs--36" style={{"maxWidth":""}} content={"<span style=\"color: var(--black);\"><br><br>Будем вас ждать!<br><br></span>"}>
              </Title>

              <Title className="title-box fs--26" content={"<span style=\"font-style: italic;\">С любовью,\n<br>Алина и &nbsp;Денис!</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}